// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth, Auth, connectAuthEmulator, GoogleAuthProvider, browserLocalPersistence} from 'firebase/auth';
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBxT1c8INced7b5TlQG9RO_6ORjKwW7nng",
  authDomain: "geguzine-app.firebaseapp.com",
  projectId: "geguzine-app",
  storageBucket: "geguzine-app.appspot.com",
  messagingSenderId: "540125985987",
  appId: "1:540125985987:web:a14ea840886bb0c593b676",
  measurementId: "G-XXBXY31W93"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const googleProvider = new GoogleAuthProvider();
const auth: Auth = getAuth(app);
const db = getFirestore(app);  // Firestore instance
auth.languageCode = 'lt';
auth.setPersistence(browserLocalPersistence);


// const firestore = getFirestore(app);

// Connect Firebase services to the local emulator
if (window.location.hostname === 'localhost') {
    connectAuthEmulator(auth, 'http://localhost:9099');
    connectFirestoreEmulator(db, 'localhost', 8080);
}



export { auth, googleProvider, db };