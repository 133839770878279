import CryptoJS from 'crypto-js';


export const decryptData  = (encryptedData: string | null): any => {
    if (!encryptedData) {
        // Handle the case when the data is null (e.g., return null or handle error)
        return null;
      }
      const cryptoKey = process.env.REACT_APP_CRYPTO_KEY || 'defaultFallbackKey';
      const bytes = CryptoJS.AES.decrypt(encryptedData, cryptoKey);
      const secretToken = bytes.toString(CryptoJS.enc.Utf8);
      return secretToken
};

