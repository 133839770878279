import React from 'react';

const About: React.FC = () => {


    return (
        <div className="container">
            <h2>Idėja</h2>
            <p className='concept'>
            Šios vestuvės – tai Gegužinė, kurios konceptą kūrėme įkvėpti
             Donato močiutės jaunystės prisiminimų apie itin smagiai 
             leidžiamą laiką, trankius šokius, juoką ir bendrystę su tais, 
             kurie šalia.  
<br></br>
<br></br>
Sakoma, kad Gegužinėse susipindavo tradicijos ir modernumas, jos jungė tai,
 kas pažįstama širdžiai, su tuo, kas nauja ir įdomu. Ši idėja mums artima, 
 todėl savo vestuves matome kaip šiuolaikišką Gegužinės interpretaciją, 
 kurios formulė labai paprasta:
</p>
<br></br>
<ul style={{ textAlign: 'justify'}}>  
<li>Grynas oras – galėsim grožėtis bundančia gamta ir laisvai judėti.</li>
<li>Jauki atmosfera - kai svarbiausia nuoširdumas, o ne formalumas.</li>
<li>Linksmybės ir kūryba – šokiai, dainos ir laisvė švęsti gyvenimą taip, 
    kaip tuo metu norisi.</li>
<li>Skanus maistas - čia komentarų tiesiog nereikia 🙂</li>
</ul>
<br></br>
<p className='concept'>
Tai bus diena, kai galėsime susiburti, dalintis džiaugsmu ir mėgautis 
paprastais, bet prasmingais dalykais kartu. 
</p>
            
        </div>
    );
};

export default About;