import React from 'react';
import SignOutButton from './components/SignOutButton';
// import moon from './assets/moon.png'
import Menu from './components/Menu';
import Attend from './components/Attend';
import Footer from './components/Footer';

const ProtectedPage: React.FC = () => {

    return (
        <div className="App Protected">
            <h1 className='geguzine'>Gegužinė</h1>
            <Menu />
            
            {/* <img src={moon} width={250} height={250} alt='Laukiame šventės'/> */}
            {/* <p className='paragraph'>Ruošiame šventę <br></br> <b>2025 05 17</b> <br></br>*/}

            {/* <SignOutButton /> */}
            <Footer />
            {/* <p> Dizainas - Simona Masiukaitė</p> */}
        </div>
    );

    
};

export default ProtectedPage;