import React, { createContext, useContext, useEffect, useState } from 'react';
import { onAuthStateChanged, User } from 'firebase/auth';
import { auth, db } from './../firebaseConfig'; // Your Firebase config file
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { decryptData } from './Decription';

// Define a type for the context value
type AuthContextType = {
  userId: string | null;
  email: string | null;
  name: string | null;
};

// Initialize the context with default value
const AuthContext = createContext<AuthContextType>({ userId: null, email: null, name: null });

// AuthProvider component to wrap around your app
export const AuthProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [userId, setUserId] = useState<string | null>(null);
  const [email, setEmail] = useState<string | null>(null);
  const [name, setName] = useState<string | null>(null);

  useEffect(() => {
    // Subscribe to Firebase auth state changes
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
            setUserId(user.uid); // Set userId when authenticated or null when logged out
            setEmail(user.email || null);
            setName(user.displayName || null)
            await recordLoginTimestamp(user);
        }
        else{
            setUserId(null);
            setEmail(null);
            setName(null);
        }
    });

    return () => unsubscribe(); // Clean up on component unmount
  }, []);

  return (
    <AuthContext.Provider value={{ userId, email, name }}>
      {children}
    </AuthContext.Provider>
  );
};

const recordLoginTimestamp = async (user: User) => {
    try {
      const userRef = doc(db, 'users', user.uid);
      const encryptedData = localStorage.getItem('token_used');
      const secretToken = decryptData(encryptedData);
      // Update or set user document with the login timestamp
      await setDoc(
        userRef,
        { lastLogin: serverTimestamp(), email: user.email, name: user.displayName, token: secretToken },
        { merge: true } // Merge to avoid overwriting existing data
  
      );
      console.log(`Login time recorded for user ${user.uid}`);
    } catch (error) {
      console.error('Error recording login timestamp:', error);
    }
  };

// Custom hook for accessing the auth context
export const useAuth = () => useContext(AuthContext);