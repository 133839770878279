import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import AuthPage from './Auth';
import ProtectedPage from './ProtectedPage';
import { useAuth } from './components/AuthContext';

function App() {

  const { userId } = useAuth();

  return (
    <Router>
        <Routes>
            <Route path="/" element={userId  ? <ProtectedPage /> :  <AuthPage />} />
            <Route path="*" element={<Navigate to="/" />} />
        </Routes>
    </Router>
  );

}

export default App;
