import { signInWithPopup, UserCredential } from 'firebase/auth';
import { auth, googleProvider, db } from '../firebaseConfig';
import { doc, getDoc, setDoc  } from 'firebase/firestore';
import CryptoJS from 'crypto-js';
import google_logo from './../assets/google.png'

const GoogleSignInButton: React.FC = () => {

    const decryptData  = (encryptedData: string | null): any => {
      if (!encryptedData) {
          // Handle the case when the data is null (e.g., return null or handle error)
          return null;
        }
        const cryptoKey = process.env.REACT_APP_CRYPTO_KEY || 'defaultFallbackKey';
        const bytes = CryptoJS.AES.decrypt(encryptedData, cryptoKey);
        const secretToken = bytes.toString(CryptoJS.enc.Utf8);
        return secretToken
      };


    const handleGoogleSignIn = async () => {
      try {

        const result: UserCredential = await signInWithPopup(auth, googleProvider);
        const user = result.user;
        console.log('Google sign-in successful, user:', user);


        const times_used = localStorage.getItem('times_used');
        const encryptedData = localStorage.getItem('token_used');
        const secretToken = decryptData(encryptedData);
  

        const tokenDocRef = doc(db, 'tokens', secretToken);
        const tokenDoc = await getDoc(tokenDocRef);

        if (tokenDoc.exists()) {
          const data = tokenDoc.data()
          data.used = times_used ? parseInt(times_used, 10) : null;
          await setDoc(tokenDocRef, data);
          
        }


      } catch (error: any) {
        if (error.code === 'auth/popup-closed-by-user') {
          console.log('Popup closed by user before completing sign-in');
          // Optionally, show a message to the user
        } else {
          console.error('Error during Google sign-in:', error);
          // Optionally, handle other errors here
        }
      }
    };


  return (
    <div className="App center" >
            <img src={google_logo} width={250} height={250} style={{ marginLeft: '-20px', marginTop: '-100px' }} alt='Together'/>
            <br/>
            <button  className="custom-button" onClick={handleGoogleSignIn}>
            Prisijunkite su Google
            </button>

    </div>
  );
};

export default GoogleSignInButton;