import React from 'react';

const DressCode: React.FC = () => {


    return (
        <div>
            <h2>Apranga</h2>
            <p className='concept'>Kaip ir įprasta Gegužinei, prie stalų sėdėti nebus būtinybės, o 
                programa laukia aktyvi, tad rinkitės aprangą, su kuria jausitės 
                patogiai, gražiai ir šventiškai.</p>
                <br></br>
            <p className='concept'>
                P.S. progų prisėsti ir kėdžių bus!
            </p>
        </div>
    );
};

export default DressCode;