import React, { useState } from 'react';
import './Footer.css';

const Footer: React.FC = () => {

    // const handleContactSubmit = (message: string) => {
    //     console.log('Message received:', message);
    //     // Here you could add Firebase or other API call to send the message.
    //   };

    // const [showContact, setShowContact] = useState(false);
  return (
    <footer className="footer">
      {/* <div className="footer-content"> */}
        {/* <div className="footer-section">
          <h4>About Us</h4>
          <p>Learn more about our app and mission.</p>
          <a href="/about" className="footer-link">About</a>
        </div> */}

        {/* <div className="footer-section"> */}
          {/* <h4>Contact</h4> */}
          {/* <ContactPopup onSubmit={handleContactSubmit} /> */}
          {/* <button className='custom-button' onClick={() => setShowContact(true)}>Contact Us</button> */}
          {/* {showContact && <ContactForm onClose={() => setShowContact(false)} />} */}
          {/* <p>arba</p> */}
          {/* <a href="mailto:info@geguzines.lt" className="footer-link">info@geguzines.lt</a> */}
        {/* </div> */}

        {/* <div> */}

      {/* </div> */}
      {/* </div> */}

      <div className="footer-bottom">
        <p>&copy; {new Date().getFullYear()} Geguzines.lt. Designed by Simona</p>
        {/* <a href="/privacy-policy" className="footer-link">Privacy Policy</a> | 
        <a href="/terms" className="footer-link">Terms of Service</a> */}
      </div>
    </footer>
  );
};

export default Footer;
